import * as React from 'react'
import styled from 'styled-components'

import * as behaviours from '../../../behaviours'
import * as hooks from '../../../hooks'
import * as S from '../../../styles'

import { Field } from './Field'
import { FieldStatus } from './FieldStatus'

export type TextFieldProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> & {
  context?: string
  description?: string
  error?: React.ReactNode
  help?: React.ReactNode
  /** @default String(Math.random()) */
  label?: React.ReactNode
  prefix?: React.ReactNode
  success?: React.ReactNode
  format?: 'small' | 'wide'
}

function TextFieldComponent({
  children,
  className,
  context,
  description,
  error,
  help,
  id,
  label,
  prefix,
  success,
  format = 'wide',
  ...inputProps
}: TextFieldProps): React.ReactElement<TextFieldProps> {
  const stableId = React.useId()
  const [length, setLength] = React.useState(String(inputProps.value ?? '').length)
  const { classList, status } = behaviours.getStatusClassName(className, error, success)

  const maxLength = inputProps.maxLength

  hooks.useIsoLayoutEffect(() => {
    if (typeof inputProps.value === 'string') {
      setLength(inputProps.value.length)
    }
  }, [inputProps.value])

  return (
    <TextFieldWrapper
      className={classList.filter(Boolean).join(' ')}
      context={context}
      htmlFor={id ?? stableId}
      label={label}
      description={description}
      help={help}
      format={format}
    >
      {children}
      <S.input.InputsWrapper>
        {prefix && <S.input.Prefix>{prefix}</S.input.Prefix>}
        <S.input.Input {...inputProps} format={format} onChange={handleChange} status={status} id={id ?? stableId} />
      </S.input.InputsWrapper>
      {(error || success || maxLength !== undefined) && (
        <FieldStatus error={error} success={success}>
          {maxLength !== undefined && (
            <S.input.Count isError={length > maxLength}>
              {length}/{maxLength}
            </S.input.Count>
          )}
        </FieldStatus>
      )}
    </TextFieldWrapper>
  )

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const onChange = inputProps.onChange
    onChange && onChange(event)
    setLength(event.target.value.length)
  }
}

export const TextFieldWrapper = styled(Field)`
  ${S.field.Message} {
    margin-top: 4px;
  }

  ${S.input.Count} {
    margin-top: 10px;
    flex: 0 0 auto;
    margin-left: auto;
  }

  ${S.field.Title}, ${S.field.Description} {
    margin-bottom: 7px;
  }

  ${S.input.Input} {
    width: 100%;
  }
`

/** will forward any other props to the underlying `<input>` */
export const TextField = styled(TextFieldComponent)<TextFieldProps>``
TextField.displayName = 'TextField'
